/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import Versioning from "../components/Versioning";

const Footer = () => {
  return (
    <div className="footer">
      <div></div>
      <div className="copyright">
        Designed and Powered by &nbsp;
        <a
          css={css`
            color: white;
            text-decoration: none;
            font-weight: bold;
          `}
          href="https://ideabreed.com.np/"
          target="_blank"
          rel="noreferrer"
        >
          Ideabreed IT Solution Pvt. Ltd.
        </a>
      </div>
      <Versioning />
    </div>
  );
};

export default Footer;
